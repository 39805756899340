import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components/macro'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import {
  BackButton,
  Button,
  ComponentWrapper,
  Row,
  Column,
  SectionTitle,
  StokrLoader,
  CryptoAddress,
} from '@stokr/components-library'
import { UserConsumer } from 'context/User/UserContext'
import fixDecimals from 'utils/fix-decimals'
import InfoPanel from 'components/InfoPanel/InfoPanel'
import { ExchangeRatesContext } from 'context/ExchangeRates/ExchangeRatesContext'
import { ProjectContext } from 'context/Project/ProjectContext'

const ConfirmContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Button} {
    max-width: 500px;
  }

  @media screen and (max-width: 767px) {
    ${Button} {
      max-width: 400px;
    }
  }
`

const SaleClosed = () => {
  const { exchangeRates } = useContext(ExchangeRatesContext)
  const { project } = useContext(ProjectContext)
  const [shouldRenderPage, setShouldRenderPage] = useState(false)

  useEffect(() => {
    setShouldRenderPage(true)
  }, [])

  return shouldRenderPage ? (
    <UserConsumer>
      {({ user }) => (
        <Layout
          progress={{
            items: [
              { id: 'select-address', handleClick: () => {} },
              { id: 'order-tokens', handleClick: () => {} },
              { id: 'confirm', handleClick: () => {} },
            ],
            doneIndex: -1,
            activeIndex: 0,
          }}
          footerColor="red"
        >
          <SEO
            title={`Invest in ${project.name}`}
            keywords={['gatsby', 'application', 'react']}
          />
          <Row main>
            <Column part={8}>
              <ComponentWrapper>
                <BackButton href={`https://stokr.io/${project.name}`}>
                  BACK TO INVESTMENT OPPORTUNITY
                </BackButton>
              </ComponentWrapper>

              <ComponentWrapper noPadding>
                <InfoPanel />
              </ComponentWrapper>

              <ComponentWrapper noPadding>
                <Row>
                  <Column part={16}>
                    <ComponentWrapper borderBottom>
                      <ComponentWrapper noPaddingHorizontal noPaddingTop>
                        <SectionTitle>{project.title}</SectionTitle>
                      </ComponentWrapper>
                      <Text>
                        <p>{project.description}</p>
                      </Text>
                    </ComponentWrapper>
                  </Column>
                </Row>
              </ComponentWrapper>
            </Column>

            <Column part={8} withBorder>
              <ComponentWrapper>
                <SectionTitle>Invest in {project.name}</SectionTitle>
              </ComponentWrapper>

              <ComponentWrapper noPaddingTop>
                <Text>
                  <h3>THE SALE IS CLOSED</h3>
                  <p>
                    The sale of {project.tokenSymbol} has closed. Please go back
                    to the venture page to learn more about it.
                  </p>
                </Text>
              </ComponentWrapper>

              <ComponentWrapper style={{ marginBottom: '5rem' }}>
                <ConfirmContainer>
                  <Button
                    type="submit"
                    fluid
                    onClick={() =>
                      window.location.replace(
                        `https://stokr.io/${project.name}`,
                      )
                    }
                  >
                    BACK TO INVESTMENT OPPORTUNITY
                  </Button>
                </ConfirmContainer>
              </ComponentWrapper>
            </Column>
          </Row>
        </Layout>
      )}
    </UserConsumer>
  ) : (
    <StokrLoader />
  )
}

export default SaleClosed
