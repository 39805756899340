import React, { useEffect, useState, useContext } from 'react'
import { useCustomNav } from 'utils/customNavigate'
import * as Yup from 'yup'
import parse from 'html-react-parser'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import {
  BackButton,
  Button,
  Row,
  Column,
  ComponentWrapper,
  CryptoAddressWrapper,
  SectionTitle,
  StokrLoader,
  StepController,
  StepControllerProvider,
  StepControllerConsumer,
  Switch,
  CryptoAddress,
  AgreementItem,
} from '@stokr/components-library'
import InfoPanel from 'components/InfoPanel/InfoPanel'
import { Formik } from 'formik'
import Form from 'components/Form/Form'
import { SwitchInTitleWrapper } from 'styles/Pages/common.styles'
import { Modal, ModalInner, ModalBack } from 'components/Modal/Modal'
import {
  SignSubIntro,
  SignSubConnectBitfinex,
  SignSubStep,
  BtcPayCheckout,
} from 'components/SigningSubflow'
import fixDecimals from 'utils/fix-decimals'
import * as urls from 'constants/paths'
import { OrderContext } from 'context/Order/OrderContext'
import { UserContext } from 'context/User/UserContext'
import { ProjectContext } from 'context/Project/ProjectContext'
import Helmet from 'react-helmet'
import { saveAs } from 'utils/saveAs'
import { TermsOfOfferingLink } from 'components/AgreementItem/AgreementItem.styles'
import { StyledFormError } from 'components/InvestCalculator/InvestCalculator.styles'
import { useLocation } from 'react-router-dom'
import { CheckboxContext } from 'context/Checkbox/CheckboxContext'
import { useCooldown } from '@stokr/components-library/dist/utils/customHooks'
import PaymentModal from '@stokr/components-library/dist/components/Modal/PaymentModal'
import { getCurrencySymbol } from '@stokr/components-library/dist/utils/formatCurrencyValue'

const isDevMode =
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_CHECKBOX_PREFILL === 'false'
    ? true
    : false

const stepsNames = ['wallet', 'connect', 'transaction', 'send']

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const capitalizeProjectName = (projectName) => {
  const splitName = projectName.split('-')
  return splitName.map((name) => capitalizeFirstLetter(name)).join('-')
}

const timerSeconds = 1800

const AlmostDonePage = (props) => {
  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  const {
    order,
    createInvoiceBtc,
    storeInvestment,
    updateInvestment,
    handleFileDownload,
  } = useContext(OrderContext)
  const { project } = useContext(ProjectContext)
  const { user } = useContext(UserContext)
  const { getCheckboxGroup, setCheckedCheckbox, checkedCheckboxes } =
    useContext(CheckboxContext)

  const navigator = useCustomNav()
  const location = useLocation()

  const [offeringDocuments, setOfferingDocuments] = useState()
  const [isDocDownloaded, setisDocDownloaded] = useState(false)
  const [errorDocDownload, seterrorDocDownload] = useState(null)

  //checkbox
  const [investmentCheckboxes, setInvestmentCheckboxes] = useState()

  //investment state
  const [isStoringInvestment, setIsStoringInvestment] = useState(false)
  const [invoiceID, setInvoiceID] = useState(null)
  const [storedInvestment, setStoredInvestment] = useState(null)

  // Modal
  const [modalOpen, setModalOpen] = useState()
  const [wallet, setWallet] = useState(null)

  // Before-After switch
  const [isAfterSelected, setIsAfterSelected] = useState(
    location.state && location.state.isAfterSelected,
  )

  const { secondsRemaining, startCooldown, hasTimerFinished } =
    useCooldown(timerSeconds)

  useEffect(() => {
    if (!user.selectedAddress) {
      navigator.navigate(`/${project.name}`)
    } else {
      fetchCheckbox()
    }
  }, [])

  useEffect(() => {
    if (user.selectedAddress) {
      //we need to order documents by the order below
      const orderDocuments = [
        'investment-agreement',
        'offering-terms',
        'terms-conditions',
        'supplement',
        'offering-document',
        'country-disclosure',
        'other',
      ]

      let offeringDocs = project?.documents
        ?.filter((doc) => !doc.investmentId) //filter out investment specific docs
        .filter((doc) =>
          user.isPrivateSale ? doc.isPrivateSaleDoc : doc.isPublicSaleDoc,
        )
        .filter((doc) => {
          if (doc.fileType === 'country-disclosure') {
            return doc.countries?.includes(user.country)
          }
          if (
            (doc.fileType === 'supplement' ||
              doc.fileType === 'terms-conditions') &&
            project.type === 'tranches'
          ) {
            return doc.tranches?.includes(order.name)
          }

          return true
        })

      //sort the documents by the sorting above
      offeringDocs &&
        offeringDocs.sort((a, b) => {
          return (
            orderDocuments.indexOf(a.fileType) -
            orderDocuments.indexOf(b.fileType)
          )
        })

      setOfferingDocuments(offeringDocs)

      //set shouldRenderPage to true if we have the checkbox group ready
      if (investmentCheckboxes) setShouldRenderPage(true)
    } else {
      navigator.navigate(`/${project.name}`)
    }
  }, [user])

  const fetchCheckbox = async () => {
    try {
      const result = await getCheckboxGroup({ group: 'investmentCheckboxes' })
      if (result != null) {
        setInvestmentCheckboxes(result)
        //if shouldRenderPage is false, set to true when the checkboxes are fetched
        if (!shouldRenderPage) setShouldRenderPage(true)
      }
    } catch (error) {
      console.log('🚀 ~ file: almost-done.js:170 ~ error:', error)
    }
  }

  const onSubmit = async () => {
    let investment
    if ((user.isBTCPay || user.isIBAN || user.isBMN1) && !storedInvestment) {
      setIsStoringInvestment(true)
      try {
        const data = {
          project: project._id,
          isPrivateSale: user.isPrivateSale,
          tokenAmount: fixDecimals(
            order.tokenAmount,
            project.tokenRoundingDecimals,
          ),
          tokenPrice: order.tokenPrice,
          currencyType: user.currencyInternalReference,
          currencyAmount: order.currencyValue,
          tokenName: project.tokenName,
          tokenSymbol: project.tokenSymbol,
          investorTxStatus: 'Started',
          checkedCheckboxes,
        }
        if (order.feeInEURorUSD) {
          if (order.feePercentage) data.feePercentage = order.feePercentage
          data.feeInEURorUSD = order.feeInEURorUSD
        }

        if (project.isLiquid) {
          investment = await storeInvestment({
            ...data,
            GAID: user.selectedAddress?.address,
            tokenUID: project.secondaryAssetId,
          })
          if (user.isBMN1) {
            startCooldown()
          }
        } else {
          throw new Error('Invalid project token issuance type')
        }
        setStoredInvestment(investment)
      } catch (error) {
        console.error(error)
        navigator.navigate(urls.OOPS, {
          state:
            error?.response?.status === 401 || error?.response?.status === 403
              ? {
                  message:
                    'We are sorry, your session expired, Please log in again.',
                  tokenExpired: true,
                  ctaLabel: 'GO TO LOGIN',
                }
              : {},
        })
        return
      }
    }

    if (user.isIBAN) {
      return navigator.navigate(`/${project.name}/showiban`)
    } else if (user.isBTCPay) {
      if (!storedInvestment) {
        const { btcpayInvoiceId } = await createInvoiceBtc({
          investmentId: investment._id,
        })
        setInvoiceID(btcpayInvoiceId)
        try {
          await updateInvestment(
            investment._id,
            'Started',
            null,
            btcpayInvoiceId,
          )
        } catch (error) {
          console.error(error)
        }
      }
      setModalOpen('btcpay')
    } else if (user.isBMN1) {
      setModalOpen('bmn1')
    } else {
      setModalOpen('bitfinex')
      //set wallet immediately bc there's no other option
      setWallet('bitfinex')
    }
  }

  const handleBeforeAfter = (e) => {
    if (e === 'AFTER') {
      setIsAfterSelected(true)
    } else if (e === 'BEFORE') {
      setIsAfterSelected(false)
    }
  }

  const onBackButtonClick = (stepController, prevStepIndex, doneStep) => {
    if (prevStepIndex === 0) {
      setWallet(null)
    }
    stepController.changeStep(
      stepsNames[prevStepIndex],
      prevStepIndex,
      doneStep,
    )
  }

  const handleFileClick = async (file) => {
    if (!file?.length > 0) return

    try {
      const fileIds = file?.map((x) => x._id)

      const blobData = await handleFileDownload({ documentIds: fileIds })

      saveAs(
        blobData,
        `Investment_Agreement_Offering_Documents_${capitalizeProjectName(
          project.name,
        )}.pdf`,
      )
      setisDocDownloaded(true)
      seterrorDocDownload(null)
    } catch (error) {
      console.log('🚀 ~ error', error)
    }
  }

  const validationSchema = Yup.object().shape({
    offeringDocument: Yup.bool().oneOf([true], 'Agree is required'),
    investmentRisk: Yup.bool().oneOf([true], 'Agree is required'),
    offeringFilled: Yup.bool().oneOf([true], 'Agree is required'),
    investmentCompliance: Yup.bool().oneOf([true], 'Agree is required'),
  })

  return shouldRenderPage ? (
    <Layout
      progress={{
        items: [
          {
            id: 'select-address',
            handleClick: () => {},
          },
          {
            id: 'order-tokens',
            handleClick: () => {},
          },
          {
            id: 'confirm',
            handleClick: () => {},
          },
        ],
        doneIndex: 1,
        activeIndex: 2,
      }}
      footerColor="red"
    >
      <Helmet>
        <script
          id="btcpay-integration"
          src="https://btcpay.stokr.io/modal/btcpay.js"
        ></script>
      </Helmet>
      <SEO
        title={`Invest in ${project.name}`}
        keywords={['gatsby', 'application', 'react']}
      />
      <Row main>
        <Column part={8}>
          <ComponentWrapper>
            <BackButton>BACK</BackButton>
          </ComponentWrapper>

          <ComponentWrapper noPadding>
            <InfoPanel />
          </ComponentWrapper>

          <ComponentWrapper borderBottom>
            <SectionTitle>
              {project.isLiquid ? 'YOUR REGISTERED LIQUID ADDRESS' : ''}
              <SwitchInTitleWrapper right>
                <Switch
                  value={isAfterSelected ? 'AFTER' : 'BEFORE'}
                  value1="BEFORE"
                  value2="AFTER"
                  handleChange={(e) => {
                    handleBeforeAfter(e)
                  }}
                />
              </SwitchInTitleWrapper>
            </SectionTitle>
          </ComponentWrapper>

          {project.isLiquid ? (
            <CryptoAddress
              eqValueFontSize={13}
              title={''}
              status={''}
              data={
                user.isBMN1
                  ? {
                      value: isAfterSelected
                        ? fixDecimals(
                            user.afterValues?.totalCurrencyBalance,
                            user.currencyDecimals,
                          )
                        : fixDecimals(
                            user.selectedAddress.currencyBalanceBMN1,
                            user.currencyDecimals,
                          ),
                      unit: user.currencySymbol,
                      eqValue: isAfterSelected
                        ? fixDecimals(
                            user.afterValues?.totalCurrencyBalanceinEURorUSD, //btc balance after
                            8,
                          )
                        : fixDecimals(
                            user.selectedAddress.currencyBalanceBMN1inBTC,
                            8,
                          ),
                      eqUnit: 'BTC',
                    }
                  : {
                      value: user.selectedAddress?.address,
                      shortAddress: true,
                      tooltip: true,
                    }
              }
              info={{
                value: `${project.tokenSymbol} ${
                  isAfterSelected
                    ? fixDecimals(
                        user.afterValues.totalTokenBalance,
                        project.tokenRoundingDecimals,
                      )
                    : fixDecimals(
                        user.selectedAddress?.balance,
                        project.tokenRoundingDecimals,
                      )
                }`,
                eqValue: isAfterSelected
                  ? fixDecimals(
                      user.afterValues.totalTokenBalanceinEURorUSD,
                      project.euroDecimals,
                    )
                  : fixDecimals(
                      user.selectedAddress?.tokenBalanceinEURorUSD,
                      project.euroDecimals,
                    ),
                eqUnit: `= ${project.denominationSymbol}`,
                noEqualsToSign: 'true',
              }}
              wrapped
              borderBottom
            />
          ) : (
            <></>
          )}

          <ComponentWrapper grow />
        </Column>

        <Column part={8} withBorder>
          <ComponentWrapper>
            <SectionTitle>Invest in {project.name}</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper noPaddingTop>
            <Text>
              <h3>{"HOLD FIRE! YOU'RE ALMOST DONE"}</h3>
              <p>
                {
                  'But first... Please read the below very carefully for your own benefit.'
                }
              </p>
            </Text>
          </ComponentWrapper>

          <ComponentWrapper>
            <SectionTitle>IMPORTANT</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper noPadding>
            <Formik
              initialValues={{
                offeringDocument: isDevMode,
                recover: isDevMode,
                investmentRisk: false,
                offeringFilled: false,
                investmentCompliance: false,
                early: isDevMode,
                tokens: order.tokenAmount,
              }}
              validationSchema={validationSchema}
              onSubmit={(e) => {}}
            >
              {({ values, handleChange, handleBlur }) => {
                const submitDisabled =
                  !values.offeringDocument ||
                  !values.investmentRisk ||
                  !values.offeringFilled ||
                  !values.investmentCompliance

                return (
                  <Form>
                    {investmentCheckboxes &&
                      Object.entries(investmentCheckboxes).map(
                        ([label, checkbox]) => {
                          //for the offeringDocument label, we need to display link and handle OnClick event for the link
                          if (label === 'offeringDocument') {
                            return (
                              <ComponentWrapper noPaddingTop key={label}>
                                <AgreementItem
                                  text={parse(checkbox.text, {
                                    replace: (domNode) => {
                                      //change <u> tag to <TermsOfOfferingLink> tag and add onClick event
                                      if (
                                        domNode.type === 'tag' &&
                                        domNode.name === 'u'
                                      ) {
                                        return (
                                          <TermsOfOfferingLink
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                              handleFileClick(offeringDocuments)
                                            }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                              color: 'inherit',
                                              cursor: 'pointer',
                                            }}
                                          >
                                            {domNode.children[0]?.data}
                                          </TermsOfOfferingLink>
                                        )
                                      }
                                    },
                                  })}
                                  checkbox={{
                                    id: label,
                                    name: label,
                                    text: parse(checkbox.agreementText),
                                    value: 'agree2',
                                    onChange: async (e) => {
                                      if (
                                        !user.testingUser &&
                                        !isDocDownloaded
                                      ) {
                                        if (!errorDocDownload)
                                          seterrorDocDownload(
                                            'Please download the offering documents before you can proceed',
                                          )
                                      } else {
                                        handleChange(e)
                                        setCheckedCheckbox(e)
                                      }
                                    },
                                    onBlur: handleBlur,
                                    checked: values.offeringDocument,
                                  }}
                                />
                                <StyledFormError
                                  show={errorDocDownload}
                                  paddingTop
                                >
                                  {errorDocDownload}
                                </StyledFormError>
                              </ComponentWrapper>
                            )
                          } else
                            return (
                              <ComponentWrapper noPaddingTop key={label}>
                                <AgreementItem
                                  text={parse(checkbox.text)}
                                  checkbox={{
                                    id: label,
                                    name: label,
                                    text: parse(checkbox.agreementText),
                                    value: 'agree2',
                                    onChange: (e) => {
                                      handleChange(e)
                                      setCheckedCheckbox(e)
                                    },
                                    onBlur: handleBlur,
                                    checked: values[label],
                                  }}
                                />
                              </ComponentWrapper>
                            )
                        },
                      )}

                    {isStoringInvestment ? (
                      <ComponentWrapper borderTop borderBottom>
                        <CryptoAddressWrapper
                          refresh={
                            'Just a second, we are processing your investment'
                          }
                        >
                          <CryptoAddress
                            eqValueFontSize={13}
                            title="your address"
                            address={''}
                            data={{
                              value: '',
                            }}
                          />
                        </CryptoAddressWrapper>
                      </ComponentWrapper>
                    ) : (
                      <ComponentWrapper>
                        <Button
                          onClick={onSubmit}
                          id="almost-done-continue"
                          disabled={submitDisabled}
                        >
                          Confirm and Continue
                        </Button>
                      </ComponentWrapper>
                    )}
                  </Form>
                )
              }}
            </Formik>
          </ComponentWrapper>
        </Column>
      </Row>

      {/* MODAL 
          NOTE: 
          This relys on either (isEthereum == true) or (isBTC == true) 
          but never(!) both
        */}
      {modalOpen === 'bitfinex' && (
        <Modal
          thin
          fullscreen
          isOpen
          onClose={() => {
            setWallet(null)

            setModalOpen(null)
          }}
        >
          <ModalInner noPadding>
            <StepControllerProvider>
              <StepControllerConsumer>
                {(stepController) => {
                  const prevStepIndex = stepController.activeStepIndex - 1

                  return (
                    <>
                      {
                        <ModalBack
                          onClick={() => {
                            onBackButtonClick(stepController, prevStepIndex)
                          }}
                          hide={!wallet}
                        />
                      }
                      <StepController
                        stepsProgressList={
                          wallet === 'bitfinex'
                            ? [
                                {
                                  id: 'connect',
                                  handleClick: () => {
                                    stepController.changeStep('connect', 1)
                                  },
                                },
                              ]
                            : [
                                {
                                  id: 'wallet',
                                  handleClick: () => {
                                    stepController.changeStep('wallet', 0)
                                    setWallet(null)
                                  },
                                },
                              ]
                        }
                        {...stepController}
                      >
                        <SignSubIntro
                          id="wallet"
                          changeStep={() =>
                            stepController.changeStep('connect', 1, 0)
                          }
                          setWallet={setWallet}
                        />

                        <SignSubStep id="connect">
                          {wallet === 'bitfinex' && (
                            <SignSubConnectBitfinex
                              changeStep={() =>
                                stepController.changeStep('connect', 2, 1)
                              }
                            />
                          )}
                        </SignSubStep>
                      </StepController>
                    </>
                  )
                }}
              </StepControllerConsumer>
            </StepControllerProvider>
          </ModalInner>
        </Modal>
      )}

      {modalOpen === 'btcpay' && (
        <>
          <BtcPayCheckout
            invoiceId={invoiceID}
            onEnter={() => {
              setIsStoringInvestment(false)
            }}
            onLeave={() => {
              setModalOpen(null)
              setIsStoringInvestment(false)
            }}
          />
        </>
      )}
      {modalOpen === 'bmn1' && (
        <PaymentModal
          isModalOpen
          user={user}
          isRedemption={false}
          //noCTA
          formatAmountInLink
          txTextInRed={false}
          numberOfSecuritiesText="Send securities"
          sendTokenTextRight={
            <div style={{ marginBottom: '1em' }}>
              <p style={{ margin: 0, display: 'inline' }}>
                Send {order.currencyValue}{' '}
                {getCurrencySymbol(user.currencyInternalReference)} to the
                treasury wallet below. You will receive {order.tokenAmount}{' '}
                {getCurrencySymbol(project.tokenSymbol)} on your AMP wallet:{' '}
              </p>
              <CryptoAddress
                noHead
                data={{
                  value: user.selectedAddress?.address,
                  tooltip: true,
                  shortAddress: true,
                }}
                containerStyle={{ display: 'inline-block', height: 20 }}
                fontSize={16}
              />
            </div>
          }
          error={
            hasTimerFinished && (
              <>
                <Text>
                  <p>This investment has expired </p>
                  <p>
                    Timeout. You can try again and request a new investment. If
                    you have sent a payment, please contact us at{' '}
                    <a
                      href="mailto:support@stokr.io"
                      style={{ color: 'inherit' }}
                    >
                      support@stokr.io
                    </a>
                    .{' '}
                  </p>
                  <Button
                    onClick={() => {
                      window.location.href = `https://${process.env.REACT_APP_WEBSITE_DOMAIN}/blockstream-mining2`
                    }}
                  >
                    Close
                  </Button>
                </Text>
              </>
            )
          }
          withTimer
          secondsRemaining={secondsRemaining}
          additionalInfoRight={
            <>
              <p>
                Please be aware when closing this page without transfering your
                securities, your will have to redo the investment flow.
              </p>
            </>
          }
          data={{
            projectData: {
              ...project,
              secondaryAssetId:
                '11f91cb5edd5d0822997ad81f068ed35002daec33986da173461a8427ac857e1', //bmn1 secondary asset id
            },
            tokenDecimals: 2,
            tokenAmount: order.currencyValue,
            tokenSymbol: project.tokenSymbol,
            tokenSymbolToSend: getCurrencySymbol(
              user.currencyInternalReference,
            ),
            createdAt: Date.now(),
            GAID: user.selectedAddress?.address,
            currencyType: user.currencyInternalReference,
          }}
          onCTAClick={() => {
            setWallet(null)
            setIsStoringInvestment(false)
            setModalOpen(null)
            navigator.navigate(urls.PENDING_PAYMENT)
          }}
          onModalClose={(data) => {
            console.log('🚀 ~ AlmostDonePage ~ data:', data)
            setWallet(null)
            setIsStoringInvestment(false)
            setModalOpen(null)
          }}
        />
      )}
    </Layout>
  ) : (
    <StokrLoader />
  )
}

export default AlmostDonePage
